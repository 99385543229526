<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                :key="i"
                class="white--text pa-1 pa-md-0"
                v-if="!s.router"
                :href="s.ref"
                target="_blank"
                v-text="s.label"
              />
               <router-link
                :key="i"
                class="white--text pa-1 pa-md-0"
                v-else-if="s.router"
                :to="{ name: s.ref }"
                v-text="s.label"
              />
              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${i}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; 2020 Cantdelimar
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        {
          label: 'Facebook',
          ref: 'https://es-es.facebook.com/cantdelimar',
          router: false,
        },
        {
          label: 'Twitter',
          ref: 'https://twitter.com/hashtag/cantdelimar',
          router: false,
        },
        {
          label: 'Instagram',
          ref: 'https://www.instagram.com/cantdelimar/',
          router: false,
        },
        {
          label: 'Linkedin',
          ref: 'https://es.linkedin.com/in/angel-marcos-marcos-89376a162?trk=people_directory',
          router: false,
        },
        {
          label: 'Política de Privacidad',
          ref: 'PoliticaDePrivacidad',
          router: true,
        },
        {
          label: 'Política de Cookies',
          ref: 'PoliticaDeCookies',
          router: true,
        },
        {
          label: 'Aviso Legal',
          ref: 'AvisoLegal',
          router: true,
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
